import { genericTranslations } from "@energylab/shared-components-mono/build/translations/genericTranslations";

export const translations = () => {
    try {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            return genericTranslations;
        }

        // eslint-disable-next-line global-require
        return require("translations");
    } catch (err) {
        return genericTranslations;
    }
};
