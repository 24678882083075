/* eslint-disable global-require */
import * as React from "react";
import { registerStructure } from "@energylab/shared-components-mono/build/modules/register/registerForm/config";
import { contactConfig } from "@energylab/shared-components-mono/build/constants/config/contactConfig";
import { galleryConfig } from "@energylab/shared-components-mono/build/constants/config/galleryConfig";
import { profileConfig } from "@energylab/shared-components-mono/build/constants/config/profileConfig";
import { profileStructure } from "@energylab/shared-components-mono/build/components/profile/profileForm/config";
import { resetPasswordConfig } from "@energylab/shared-components-mono/build/constants/config/resetPasswordConfig";
import { whiteListSignUpConfig } from "@energylab/shared-components-mono/build/constants/config/whiteListSignUpConfig";
import { nationalities } from "@energylab/shared-components-mono/build/constants/nationalities";
import { ConfigReducerState } from "@energylab/shared-components-mono/build/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components-mono/build/utils/browserLocale";
import { FormatMessage } from "@energylab/shared-components-mono/build/components/formatMessage/formatMessage";
import * as environment from "environment";

const locales = ["nl", "en"];
const defaultLocale = "en";
profileConfig.requiredProfileFields = [
    "givenName",
    "familyName",
    "birthDate",
    "sex",
    "unit"
];

const units = [
    {
        label: "Energy Lab",
        value: "Energy Lab"
    },
    {
        label: "Aptus",
        value: "Aptus"
    }
];

const companies = [
    {
        label: "Energy Lab",
        value: "Energy Lab"
    },
    {
        label: "Aptus",
        value: "Aptus"
    },
    {
        label: "Nortal",
        value: "Nortal"
    },
    {
        label: "PwrTeams",
        value: "PwrTeams"
    },
    {
        label: "Skelia",
        value: "Skelia"
    },
    {
        label: "Golazo",
        value: "Golazo"
    }
];
// TODO: Change company logo
export const companyLogo = "https://res.cloudinary.com/energylab/image/upload/v1561558207/generic-v2/logo.svg";

export const config: ConfigReducerState = {
    mustAcceptPolicies: ["privacy"],
    profileConfig: {
        ...profileConfig,
        structure: {
            ...profileStructure,
            formElements: [
                ...profileStructure.formElements,
                {
                    title: "auth.fields.unit",
                    type: "select",
                    options: units.map(({ label, value }) => ({
                        title: <FormatMessage id={label} />,
                        value
                    })),
                    property: "unit",
                    required: false
                },
                {
                    title: "auth.fields.company",
                    type: "select",
                    options: companies.map(({ label, value }) => ({
                        title: <FormatMessage id={label} />,
                        value
                    })),
                    property: "company",
                    required: false,
                    defaultValue: undefined
                }
            ]
        }
    },
    registerConfig: {
        backgroundImage: "https://res.cloudinary.com/energylab/image/upload/v1561558301/generic-v2/authbg.jpg",
        requireEmailValidation: false,
        structure: registerStructure
    },
    resetPasswordConfig,
    galleryConfig,
    ambassadorSettingsConfig: {
        enableRelive: false,
        enableComments: true
    },
    socialConfig: {
        disableInstagram: false,
        disableTwitter: false,
        disableFacebook: false,
        disableLinkedIn: false,
        disableOnChallenCard: false,
        hideFacebookOnCommunity: false,
        hideTwitterOnCommunity: false,
        hideInstagramOnCommunity: false,
        hideLinkedInOnCommunity: true
    },
    dashboardPlacesConfig: {
        articles: [],
        benefits: [],
        challenges: [],
        events: [],
        gallery: [],
        homePage: ["dashboard"],
        leaderBoard: [],
        mySpace: [],
        mySpaceActivities: [],
        profile: [],
        webShop: [],
        contact: ["contact_header"]
    },
    ambassadorImage: "",
    auth: {
        facebookRedirect: environment.auth.facebook,
        googleRedirect: environment.auth.google,
        anonymousToken: environment.anonymousToken
    },
    commentsConfig: {
        disabled: false,
        autoShowSubComments: false,
        autoShowInput: false
    },
    eventsConfig: {
        defaultView: "list",
        viewOptions: []
    },
    contentConfig: {
        showAuthor: true,
        useThemes: true,
        previewType: "card",
        enablePageBanner: true,
        pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1561558218/generic-v2/bannerimage.jpg",
        pageBannerOverlay: true,
        alignment: "left"
    },
    contactConfig: {
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [
            { key: "1", value: "option1" },
            { key: "2", value: "option2" }
        ],
        siteKey: contactConfig.siteKey
    },
    companyLogo,
    companyName: "Energy Lab",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig: {
        enabled: false,
        coinName: "coin"
    },
    likesConfig: {
        disabled: false
    },
    webshopConfig: {
        enabled: false,
        shopName: ""
    },
    notificationsConfig: {
        enabled: false
    },
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig: {
        locales,
        defaultLocale,
        redirects: [],
        hideNavigationWhenLoggedOut: true
    },
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    seo: {
        pageTitle: "Energy Lab generic platform V2",
        title: {
            nl: "Energy Lab",
            fr: "Energy Lab",
            en: "Energy Lab"
        },
        description: {
            nl: "",
            fr: "",
            en: ""
        },
        image: "",
        site: ""
    },
    uploadPreset: "development",
    newUsersConfig: {
        enabled: false,
        hasListOfNewUsers: false
    },
    leaderboardConfig: {
        pageSize: 10
    },
    socialShareConfig: {
        enabled: true,
        copyClipboardEnabled: false,
        shareWithEmail: {
            enabled: false
        }
    },
    whiteListSignUpConfig: {
        enabled: false,
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: whiteListSignUpConfig.siteKey
    }
};
